<template>
  <div>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12 lg12 style="max-width: 350px">
        <div class="logo">
          <img width="160" src="../../assets/images/logo.png" id="form"/>
        </div>
        <div v-if="isError" class="errors">{{ responseError }}</div>
        <v-form autocomplete="disabled">
          <v-icon slot="prepend" color="red">mdi-plus</v-icon>
          <v-text-field id="email" autocomplete="email" prepend-icon="mdi-account"
                        spellcheck="false" label="Email" name="loginEmail"
                        placeholder=" " persistent-placeholder
                        color="#005550" type="text"
                        :rules="!errors.first('loginEmail')? [true]: [errors.first('loginEmail')]"
                        :error-messages="errors.collect('loginEmail')" v-model="email"
                        v-validate.lazy="{ required: true, email: true }"></v-text-field>
          <v-text-field autocomplete="password" prepend-icon="mdi-lock"
                        placeholder=" " persistent-placeholder
                        v-validate.lazy="{ required: true }" id="password" label="Password"
                        name="loginPassword" type="password" color="#005550"
                        :rules="!errors.first('loginPassword') ? [true] : [errors.first('loginPassword')]"
                        :error-messages="errors.collect('loginPassword')" v-model="password"></v-text-field>
          <v-text-field autocomplete="none" prepend-icon="mdi-fingerprint"
                        v-validate.lazy="{ numeric: true, length: 6 }" id="2fa"
                        color="#005550" label="Two-factor code"
                        name="twoFactor" spellcheck="no" v-model="twoFactor"
                        hint="Required only if enabled"
                        :rules="!errors.first('twoFactor') ? [true] : [errors.first('twoFactor')]"
                        :error-messages="errors.collect('twoFactor')"></v-text-field>
        </v-form>
        <v-card-actions class="mt-3">
          <v-spacer></v-spacer>
          <div class="holder">
            <div @click="$router.push('/forgot-password')">Forgot your password?</div>
            <v-btn dark color="#005550" width="100" @click="loginRequest">Login
            </v-btn>
          </div>
        </v-card-actions>
      </v-flex>
    </v-layout>
    <v-overlay z-index="555" opacity=".7" :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {ACTION_LOGIN} from "@/store/constants/user";

export default {
  name: "Home",
  data() {
    return {
      load: false,
      isError: false,
      responseError: "",
      email: "",
      password: "",
      twoFactor: "",
    };
  },
  methods: {
    ...mapActions({
      login: ACTION_LOGIN,
    }),
    enterEventListener(e) {
      if (e.keyCode === 13) {
        this.loginRequest();
      }
    },
    async loginRequest() {
      this.load = true;
      this.isError = false;
      const validator = await this.$validator.validate();
      if (validator) {
        try {
          await this.login({email: this.email, password: this.password, twoFaCode: +this.twoFactor});
        } catch (error) {
          if (error.response.status === 429) {
            this.isError = true;
            return this.responseError = `Exceeded the maximum number of login attempts`;
          }
          this.isError = true;
          this.responseError = error.response.data.message ? error.response.data.message : "Error occurred during login";
        } finally {
          this.load = false;
        }
      } else {
        this.load = false;
      }
    },
  },
  mounted() {
    document.body.addEventListener("keyup", this.enterEventListener);
  },
  beforeDestroy() {
    document.body.removeEventListener("keyup", this.enterEventListener);
  },
};
</script>
<style lang="scss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #f9f9f9 inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #f9f9f9 inset !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

.errors {
  font-size: 12px;
  color: red;
  margin-left: 30px;
  margin-bottom: 15px;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > div {
    font-size: 13px;
    color: #005550;
    cursor: pointer;

    &:hover {
      color: #006bc7;
    }
  }
}
</style>
