<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="360">
      <v-card class="d-flex flex-column align-center pa-3">
        <v-img class="mt-4" v-if="done" width="70" src="../../assets/images/check-circle-regular.svg"></v-img>
        <v-img v-if="failed" width="70" src="../../assets/images/exclamation-circle.svg"></v-img>
        <v-card-title
            :style="[done? {color:'#005550',fontSize:'18px',wordBreak: 'break-word'}:{color:'#f43162',fontSize:'18px',wordBreak: 'break-word'}]"
            class="text-center">
          {{ message }}
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "ResponseNotifier",
  data() {
    return {
      dialog: true
    };
  },
  props: {
    done: {
      type: Boolean,
      default: false
    },
    failed: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    }
  },
};
</script>
<style lang="scss" scoped>
#link {
  &:hover {
    cursor: pointer;
  }
}
</style>