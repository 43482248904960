const CryptoJS = require("crypto-js");

const Enigma = {
  encryptObj(obj) {
    const result = CryptoJS.AES.encrypt(JSON.stringify(obj), process.env.VUE_APP_SECRET);
    return result.toString();
  },
  decryptObj(obj) {
    const bytes = CryptoJS.AES.decrypt(obj, process.env.VUE_APP_SECRET);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  },
};

export default Enigma;
