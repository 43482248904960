import axios from "../../axios.service";
import {TokenService} from "../../storage.service";

function getParsedJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]))
    } catch (error) {
        return undefined
    }
}

class User {
    async login(payload) {
        try {
            const userData = await axios.post(`${process.env.VUE_APP_URI}auth/operator/login`, payload);
            axios.setHeaders(userData.headers["x-identity-token"]);
            const {email, role, id, permissions, twoFaEnabled} = userData.data;
            const token = userData.headers["x-identity-token"];
            const sessionExpiresAt = getParsedJwt(token).exp;

            const dataCollection = {
                email,
                role,
                id,
                twoFaEnabled,
                permissions,
                token,
                sessionExpiresAt
            };
            TokenService.saveConfig(dataCollection);

            return dataCollection;
        } catch (error) {
            throw error;
        }
    }

    async manageAuthentication(payload) {
        try {
            return await axios.patch(`${process.env.VUE_APP_URI}operators/two-fa`,payload);
        } catch (error) {
            throw error.response;
        }
    }

    async getQR() {
        try {
            const {data} = await axios.get(`${process.env.VUE_APP_URI}operators/two-fa`);
            return data;
        } catch (error) {
            throw error.response;
        }
    }

    async updatePassword(payload) {
        try {
            return await axios.patch(`${process.env.VUE_APP_URI}operators/profile`, payload);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    async forgotPassword(payload) {
        try {
            return await axios.post(`${process.env.VUE_APP_URI}operators/forgot-password`, payload);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    async getAll() {
        try {
            return await axios.get(`${process.env.VUE_APP_URI}operators`);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    async create(payload) {
        try {
            return await axios.post(`${process.env.VUE_APP_URI}operators`, payload);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    async delete(id) {
        try {
            return await axios.delete(`${process.env.VUE_APP_URI}operators/${id}`);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    async update(payload) {
        try {
            return await axios.patch(`${process.env.VUE_APP_URI}operators/${payload.id}`, payload.data);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    async getAllRoles() {
        try {
            return await axios.get(`${process.env.VUE_APP_URI}operators/roles`);
        } catch (error) {
            throw error.response.data.message;
        }
    }

    logout() {
        TokenService.removeCofnig();
        axios.removeHeaders();
    }
}

export default new User();
