<template>
  <v-snackbar v-model="config.snackbar" :color="config.color ? config.color : 'error'" :right="true"
              :timeout="3000" :top="true">
    {{ config.text }}
  </v-snackbar>
</template>
<script>
export default {
  name: "Snackbar",
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      timeout: 3000,
      x: "right",
      y: "top",
    };
  },
};
</script>