import {TokenService} from "@/services/storage.service";
import * as consts from "../constants/user";
import router from "../../router";
import store from "../store";
import User from "../../services/api-calls/user/user.service";
import {determineDefaultRoute} from "@/services/permissionHelper";

const state = {
    accessToken: TokenService.getConfig("token"),
    isLoggedIn: false,
    role: TokenService.getConfig("role"),
    twoFactor: TokenService.getConfig("twoFaEnabled"),
    email: TokenService.getConfig("email"),
    permissions: TokenService.getConfig("permissions"),
    userID: TokenService.getConfig("id"),
    sessionExpiresAt: TokenService.getConfig("sessionExpiresAt"),
    sessionExpired: false,
};

const actions = {
    //LOGIN
    async [consts.ACTION_LOGIN]({commit}, payload) {
        try {
            const data = await User.login(payload);
            let userSettings = {
                email: data.email,
                role: data.role,
                permissions: data.permissions,
                token: data.token,
                twoFactor: data.twoFaEnabled,
                id: data.id,
                sessionExpiresAt: data.sessionExpiresAt
            };
            commit(consts.SET_USER_CONFIG, userSettings);
            commit(consts.CHANGE_AUTH_STATUS, data.twoFaEnabled);

            if (data.twoFaEnabled) {
                router.push(determineDefaultRoute(userSettings.permissions)).catch((err) => {
                });
            } else {
                router.push("/authentication")
            }
            commit(consts.IS_LOGGED_IN, true);
            return true;
        } catch (error) {
            commit(consts.IS_LOGGED_IN, false);
            throw error;
        }
    },
    [consts.ACTION_SESSION_EXPIRED]({commit}) {
        commit(consts.SESSION_EXPIRED);
    },
    //Change auth status internal
    [consts.ACTION_CHANGE_AUTH_STATUS]({commit}, payload) {
        commit(consts.CHANGE_AUTH_STATUS, payload);
    },

    //Check if logged in
    [consts.ACTION_IS_LOGGED_IN]({commit}, payload) {
        commit(consts.IS_LOGGED_IN, payload);
    },
    //LOGOUT
    [consts.ACTION_LOGOUT]({commit}) {
        User.logout();
        store.dispatch(consts.ACTION_IS_LOGGED_IN, false);
        commit(consts.LOGOUT_SUCCESS);
        router.push("/").catch((err) => {
        });
    },
};

const mutations = {
    [consts.SESSION_EXPIRED]: (state) => {
        state.sessionExpired = !state.sessionExpired;
    },
    //Set user settings
    [consts.SET_USER_CONFIG]: (state, config) => {
        state.email = config.email;
        state.twoFactor = config.twoFactor
        state.accessToken = config.token;
        state.permissions = config.permissions;
        state.role = config.role;
        state.userID = config.id;
        state.sessionExpiresAt = config.sessionExpiresAt
    },

    //Change auth status internal
    [consts.CHANGE_AUTH_STATUS]: (state, payload) => {
        state.twoFactor = payload;
    },

    //Check if logged in
    [consts.IS_LOGGED_IN]: (state, payload) => {
        state.isLoggedIn = payload;
    },

    //Clear user settgings
    [consts.LOGOUT_SUCCESS]: (state) => {
        state.accessToken = "";
        state.role = "";
        state.email = "";
        state.permissions = [];
        state.twoFactor = "";
        state.sessionExpiresAt = ""
    },
};
export default {
    state,
    actions,
    mutations,
};
