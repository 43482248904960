<template>
  <v-app fluid>
    <Sidebar :drawer="isSidebarOpen" v-if="isLoggedIn"/>
    <Navigation v-show="isLoggedIn"/>
    <v-main :class="{ login: !isLoggedIn, loggedIn: isLoggedIn }">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <Snackbar :config="snackbarConfig"/>
    <ResponseNotifier v-if="loggedOutSessionExpired" failed @closeRequest="loggedOutSessionExpired = false;"
                      :message="'Your session has expired and you have been logged out.'"/>
  </v-app>
</template>
<script>
import Sidebar from "./components/Navigation/Sidebar";
import Snackbar from "./components/Common/Snackbar";
import {mapActions, mapState} from "vuex";
import moment from "moment";
import {ACTION_LOGOUT} from "@/store/constants/user";
import ResponseNotifier from "@/components/Common/ResponseNotifier";

const Navigation = () => import("./components/Navigation/Navigation");

export default {
  name: "App",
  components: {
    ResponseNotifier,
    Sidebar,
    Navigation,
    Snackbar,
  },
  data() {
    return {
      syncDrawer: "",
      now: new Date,
      loggedOutSessionExpired: false,
      logoutInterval: null,
      snackbarConfig: {
        text: "Session expired",
        snackbar: false,
      },
    };
  },
  watch: {
    sessionExpiration() {
      this.snackbarConfig.snackbar = true;
    },
    now() {
      if (moment(this.now).isSameOrAfter(this.sessionExpiresAt)) {
        this.loggedOutSessionExpired = true;
        this.logout()
      }
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn,
      isSidebarOpen: (state) => state.isSidebarOpen,
      sessionExpiration: (state) => state.user.sessionExpired,
      sessionExpiresAt: (state) => state.user.sessionExpiresAt,
    })
  },
  methods: {
    ...mapActions({
      logout: ACTION_LOGOUT,
    })
  },
  mounted() {
    this.logoutInterval = setInterval(() => this.now = Math.ceil(Date.now() / 1000), 1000 * 30)
  },
  beforeDestroy() {
    clearInterval(this.logoutInterval)
  }
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}

.login {
  padding: 0;
  background: #fff;
}

.loggedIn {
  background: #fafafa;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
</style>
