import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import * as types from "./types";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isSidebarOpen: true,
        processExplorerMetadata: {},
        processExplorerService: 'wallet'
    },

    mutations: {
        [types.TOGGLE_SIDEBAR]: (state, payload) => {
            state.isSidebarOpen = payload;
        },
        [types.SET_PROCESS_META]: (state, payload) => {
            state.processExplorerMetadata = payload;
        },
        [types.SET_PROCESS_EXPLORER_SERVICE]: (state, payload) => {
            state.processExplorerService = payload
        }
    },

    actions: {
        [types.ACTION_TOGGLE_SIDEBAR]({commit}, payload) {
            commit(types.TOGGLE_SIDEBAR, payload);
        },
        [types.ACTION_SET_PROCESS_META]({commit}, payload) {
            commit(types.SET_PROCESS_META, payload);
        },
        [types.ACTION_SET_PROCESS_EXPLORER_SERVICE]({commit}, payload) {
            commit(types.SET_PROCESS_EXPLORER_SERVICE, payload);
        },
    },

    modules: {
        user,
    },
});
