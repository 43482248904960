export function generateRolePermissions(allUserPermissions, roles, includeAll = false) {
    roles.forEach((role) => {
        role.humanReadablePermissions = []
        allUserPermissions.forEach((perm) => {
            const isFull = perm.full.every((perm) => role.permissions.includes(perm))
            const isViewOnly = perm.view.length ? perm.view.every((perm) => role.permissions.includes(perm)) : false

            const access = isFull ? "Full access" : isViewOnly ? "View access" : "No access"

            if (includeAll) {
                role.humanReadablePermissions.push({name: perm.name, access: access})
            } else {
                if (access !== "No access") role.humanReadablePermissions.push({name: perm.name, access: access})
            }
        })
    })
    return roles
}

export function determineDefaultRoute(permissions) {
    if (permissions.includes("GENERATE_INVOICE")) return "/invoice"
    if (permissions.includes("MANAGE_BLOCKED_CRYPTO_DEPOSITS")) return "/unblock-transfer"

    return "/change-password"
}