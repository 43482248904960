const CONFIG = "config";
import Enigma from "../services/encryption.service";

const TokenService = {
    saveConfig(obj) {
        const config = Enigma.encryptObj(obj);

        localStorage.setItem(CONFIG, config);
    },
    getConfig(option) {
        if (localStorage.getItem(CONFIG)) {
            let res = Enigma.decryptObj(localStorage.getItem(CONFIG));

            return res[option];
        }
    },
    editConfig(option, val) {
        if (localStorage.getItem(CONFIG)) {
            let res = Enigma.decryptObj(localStorage.getItem(CONFIG));
            res[option] = val;
            this.saveConfig(res);
        }
    },

    removeCofnig() {
        localStorage.removeItem(CONFIG);
    }
};

export {TokenService};
