import axios from "axios";
import { TokenService } from "./storage.service";
import store from "../store/store";
import { ACTION_LOGOUT, ACTION_SESSION_EXPIRED } from "@/store/constants/user";

class Axios {
  constructor() {
    const instance = axios.create();

    instance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
          if (error.response.data.message === "Invalid or expired 2FA verification code.") throw error;
          if (error.response.request.responseURL.includes("/auth/login")) {
            store.dispatch(ACTION_LOGOUT);
          } else {
            store.dispatch(ACTION_LOGOUT);
            store.dispatch(ACTION_SESSION_EXPIRED);
          }
        } else if (error.message === "Network Error") {
          store.dispatch(ACTION_LOGOUT);
        }

        throw error;
      }
    );
    instance.setHeaders = this.setHeaders;
    instance.removeHeaders = this.removeHeaders;
    instance.set2FAHeader = this.set2FAHeader;
    instance.remove2FAHeader = this.remove2FAHeader;
    instance.handleBlobResponse = this.handleBlobResponse;

    return instance;
  }

  setHeaders(token = null) {
    if (token) {
      this.defaults.headers.common["Authorization"] = `FRX-OPR ${token}`;
    } else {
      this.defaults.headers.common[
        "Authorization"
      ] = `FRX-OPR ${TokenService.getConfig("token")}`;
    }
  }

  set2FAHeader(token) {
    this.defaults.headers.common["X-Two-Fa-Code"] = `${token}`;
  }

  remove2FAHeader() {
    delete this.defaults.headers.common["X-Two-Fa-Code"];
  }

  removeHeaders() {
    this.defaults.headers.common = {};
  }

  handleBlobResponse(uri) {
    return this.request({
      method: "get",
      url: uri,
      responseType: "blob"
    });
  }
}

export default new Axios();
